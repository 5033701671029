import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import liffapi from '@/liffapi'

export const useOrgStore = defineStore({
  id: 'organization',

  state: () => ({
    categories: useLocalStorage('categories', {}),
    notifications: [],
    org: useLocalStorage('organization', {}),
    user: null,
  }),

  getters: {
    mapUrl(state) {
      return state.org?.mapUrl
    },

    pin(state) {
      return state.org?.itemOptions?.pin
    },

    key(state) {
      return state.org?.key
    },

    slug(state) {
      return state.org?.slug
    },

    protectWallpaperUrl(state) {
      return (
        state.org?.protectOpts?.wallpaperUrl ||
        state.org?.displayOptions?.protectWallpaperUrl
      )
    },

    protectOpts(state) {
      return state.org?.protectOpts
    },

    hasAds(state) {
      return state.org?.displayOptions?.hasAds
    },

    userKey(state) {
      return state.user?.key
    },
  },

  actions: {
    loadCategories(orgKey) {
      this.notifications = this.notifications || []

      return liffapi
        .getCategories(orgKey)
        .then((result) => {
          this.categories = result?.data
          let note = {
            message: `Categories ${Object.keys(result?.data)?.length}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
        })
        .catch((error) => {
          throw new Error(`Load categories failed: ${error}`)
        })
    },

    loadOrg(orgKey) {
      this.notifications = this.notifications || []

      return liffapi.organization
        .get(orgKey)
        .then((result) => {
          this.org = result.data
          let note = {
            message: `Organization ${result?.data?.slug}`,
            body: result,
            type: 'notification',
            read: false,
          }
          this.notifications?.push(note)
        })
        .catch((error) => {
          throw new Error(`Load org failed: ${error}`)
        })
    },

    loadUser(orgKey) {
      return liffapi
        .getCurrentUser(orgKey, 'full')
        .then((result) => {
          this.org = result.org
          this.categories = result.categories
          this.user = result.user
          return result.user
        })
        .catch((error) => {
          throw new Error(`Load org failed: ${error}`)
        })
    },
  },
})
