import req from './axios';

export default {
  fetchEmails(form) {
    return req
      .get('/messages/', { params: form })
      .then((response) => response.data);
  },

  markUnread(msgKey) {
    console.log(`messages.markUnread(${msgKey})`);
    return req
      .put(`/messages/${msgKey}/unread`)
      .then((response) => response.data);
  },

  markRead(msgKey) {
    console.log(`messages.markRead(${msgKey})`);
    return req
      .put(`/messages/${msgKey}/read`)
      .then((response) => response.data);
  },

  reply(form) {
    console.log(`messages.reply(`, form, `)`);
    return req.post(`/messages/reply`, form).then((response) => response.data);
  },
};
