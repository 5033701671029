import req from './axios';

export default {
  get(claimKey) {
    console.log(`API: claims.get(${claimKey})`);
    return req.get(`/claims/${claimKey}`).then((response) => response.data);
  },

  update(claim) {
    console.log(`API: ${claim}`);
    return req
      .post(`/claims/${claim.key}`, claim)
      .then((response) => response.data);
  },

  match(claimKey, form) {
    console.log(`API: claims.match(${JSON.stringify(form)})`);
    return req
      .post(`/claims/${claimKey}/match`, form)
      .then((response) => response.data);
  },

  reject(claimKey, form) {
    console.log(`API: claims.reject(${JSON.stringify(form)})`);
    return req
      .post(`/claims/${claimKey}/reject`, form)
      .then((response) => response.data);
  },

  message(claimKey, form) {
    console.log(`API: claims.message(${JSON.stringify(form)})`);
    return req
      .post(`/claims/${claimKey}/messages`, form)
      .then((response) => response.data);
  },

  getMessages(claimKey) {
    console.log(`API: claims.getMessages(${claimKey})`);
    return req
      .get(`/claims/${claimKey}/messages`)
      .then((response) => response.data);
  },

  getActivity(claimKey) {
    console.log(`API: claims.Activity(${claimKey})`);
    return req
      .get(`/claims/${claimKey}/activity`)
      .then((response) => response.data);
  },

  updateState(claimKey, form) {
    console.log(`API: claims.updateState(${claimKey})`);
    return req
      .patch(`/claims/${claimKey}/state`, form)
      .then((response) => response.data);
  },

  sendReminder(claimKey) {
    console.log(`API: claims.sendReminder(${claimKey})`);
    return req
      .post(`/claims/${claimKey}/reminder`)
      .then((response) => response.data);
  },

  extendExpired(claimKey) {
    console.log(`API: claims.extendExpired(${claimKey})`);
    return req
      .post(`/claims/${claimKey}/extend`)
      .then((response) => response.data);
  },
};
