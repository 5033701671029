import req from './axios';

export default {
  createScanForm(form) {
    console.log(`API: orders.pack(${form})`);
    return req.put(`/orders/scanform`, form).then((response) => response.data);
  },

  pack(orderKey) {
    console.log(`API: orders.pack(${orderKey})`);
    return req
      .put(`/orders/${orderKey}/pack`)
      .then((response) => response.data);
  },

  purchaseLabel(orderId, form) {
    console.log(`API: orders.purchaseLabel`);
    return req
      .put(`/orders/${orderId}/label`, form)
      .then((response) => response.data);
  },

  list() {
    console.log(`API: orders.list`);
    return req.get(`/orders`).then((response) => response.data);
  },

  get(orderKey) {
    console.log(`API: orders.get(${orderKey})`);
    return req.get(`/orders/${orderKey}`).then((response) => response.data);
  },

  refreshTracking(orderKey) {
    console.log(`API: orders.refreshTracking(${orderKey})`);
    return req
      .get(`/orders/${orderKey}/tracking`)
      .then((response) => response.data);
  },
};
