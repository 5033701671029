import req from './axios';

export default {
  updatePrefs(userKey, form) {
    console.log(`API: users.updatePrefs(${userKey}, `, form, ')');
    return req
      .patch(`/users/${userKey}/prefs`, form)
      .then((response) => response.data);
  },

  list(orgKey) {
    console.log(`API: users.list(${orgKey})`);
    return req.get(`/users/?org=${orgKey}`).then((response) => response.data);
  },

  invitations(orgKey) {
    console.log(`API: users.invitations(${orgKey})`);
    return req
      .get(`/users/invitations?org=${orgKey}`)
      .then((response) => response.data);
  },

  createTempWorker(form) {
    console.log(`API: users.createTempWorker(`, form, `)`);
    return req.post(`/users/temporary`, form).then((response) => response.data);
  },

  invite(form) {
    console.log(`API: users.invite(`, form, `)`);
    return req.post(`/users/`, form).then((response) => response.data);
  },

  inviteAccept(token, form) {
    console.log(`API: users.inviteAccept(${token}, `, form, `)`);
    return req
      .post(`/users/invitations/${token}/accept`, form)
      .then((response) => response.data);
  },

  inviteEstablish(token, form) {
    console.log(`API: users.inviteEstablish(${token}, `, form, `)`);
    return req
      .post(`/users/invitations/${token}/establish`, form)
      .then((response) => response.data);
  },

  inviteRemove(inviteKey) {
    console.log(`API: users.inviteRemove(${inviteKey})`);
    return req
      .delete(`/users/invitations/${inviteKey}`)
      .then((response) => response.data);
  },

  inviteResend(inviteKey) {
    console.log(`API: users.inviteResend(${inviteKey})`);
    return req
      .post(`/users/invitations/resend/${inviteKey}`)
      .then((response) => response.data);
  },

  getOnboarding(token) {
    console.log(`API: users.getOnboarding(${token})`);
    return req
      .get(`/users/invitations/${token}`)
      .then((response) => response.data);
  },

  deviceAuthToken(orgKey) {
    return req
      .get(`/users/device-auth?org=${orgKey}`)
      .then((response) => response.data);
  },

  authByToken(token) {
    return req
      .post('/users/device-auth', { token: token })
      .then((response) => response.data);
  },

  getTempWorkerToken(key, orgKey) {
    return req
      .get(`/users/${key}/temp_token?org=${orgKey}`)
      .then((response) => response.data);
  },

  getWorker(key, orgKey, refresh = false) {
    return req
      .get(`/users/${key}?org=${orgKey}&refresh=${refresh}`)
      .then((response) => response.data);
  },

  updateWorker(key, orgKey, form) {
    /*
     * key: UrlsafeKey, the worker being updated
     * orgKey: UrlsafeKey, the organization being edited
     * form: Object, worker properties to update
     */
    return req
      .post(`/users/${key}?org=${orgKey}`, form)
      .then((response) => response.data);
  },

  getConsumer(key, orgKey, refresh = false) {
    return req
      .get(`/consumers/${key}?org=${orgKey}&refresh=${refresh}`)
      .then((response) => response.data);
  },

  getConsumerDetails(key, orgKey) {
    return req
      .get(`/consumers/${key}/details?org=${orgKey}`)
      .then((response) => response.data);
  },

  getProtectDetails(key, orgKey) {
    console.log(`API: consumers.getProtectDetails(${key}, ${orgKey})`);
    return req
      .get(`/consumers/${key}/protect?org=${orgKey}`)
      .then((response) => response.data);
  },
};
