import req from './axios';

export default {
  list(orgKey) {
    return req
      .get(`/valet`, { params: { organization: orgKey } })
      .then((response) => response.data);
  },

  checkout(itemKey) {
    return req
      .post(`/valet/${itemKey}/return`)
      .then((response) => response.data);
  },

  create(form) {
    return req.post(`/valet`, form).then((response) => response.data);
  },
};
