import req from './axios'

export default {
  getMessages(alertKey) {
    console.log(`API: alerts.getMessages(${alertKey})`)
    return req
      .get(`/alerts/${alertKey}/messages`)
      .then((response) => response.data)
  },
}
