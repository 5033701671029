import req from './axios';

export default {
  get(itemKey, mode) {
    console.log(`${itemKey}`);
    mode = mode || 'normal';
    return req.get(`/items/${itemKey}?mode=${mode}`).then((response) => response.data);
  },

  getByRef(itemRef) {
    console.log(`items.getByRef(${itemRef})`);
    return req.get(`/items/${itemRef}/ref`).then((response) => response.data);
  },

  getClaims(itemKey) {
    console.log(`${itemKey}`);
    return req
      .get(`/items/${itemKey}/claims`)
      .then((response) => response.data);
  },

  logItem(itemReport) {
    console.log(`API: items.logItem(`, itemReport, `)`);
    return req.post(`/items/`, itemReport).then((response) => response.data);
  },

  getEditable(itemKey) {
    return req.get(`/items/${itemKey}/edit`).then((response) => response.data);
  },

  bulkDispose(form) {
    console.log(`API: items.bulkDispose(`, form, `)`);
    return req.post(`/items/dispose/bulk`, form).then((response) => response);
  },

  dispose(itemKey, form) {
    console.log(`API: items.dispose(${itemKey}, `, form, `)`);
    return req
      .patch(`/items/${itemKey}/dispose`, form)
      .then((response) => response.data);
  },

  pickup(itemKey, form) {
    console.log('API: items.pickup(', itemKey, ',', form, ')');
    return req
      .post(`/items/${itemKey}/pickup`, form)
      .then((response) => response.data);
  },

  update(itemKey, form) {
    console.log(`API: items.update(${itemKey}, ${form})`);
    return req.put(`/items/${itemKey}`, form).then((response) => response.data);
  },

  updateState(itemKey, form) {
    console.log(`API: items.updateState(${itemKey}, `, form, `)`);
    return req
      .patch(`/items/${itemKey}/state`, form)
      .then((response) => response.data);
  },

  updateTags(itemKey, form) {
    console.log(`API: items.updateTags(${itemKey}, ${form})`);
    return req
      .patch(`/items/${itemKey}/tags`, form)
      .then((response) => response.data);
  },

  addImages(itemKey, form) {
    window.console.log(`API: items.addImages(${itemKey}, ${form})`);
    return req
      .put(`/items/${itemKey}/images`, form)
      .then((response) => response.data);
  },

  getMessages(itemKey) {
    return req
      .get(`/items/${itemKey}/messages`)
      .then((response) => response.data);
  },

  sendMessages(itemKey, form) {
    console.log(`API: items.sendMessage(${itemKey})`);
    return req
      .post(`/items/${itemKey}/messages`, form)
      .then((response) => response.data);
  },

  refresh(itemKey) {
    console.log(`items.refresh(${itemKey})`);
    return req
      .put(`/items/${itemKey}/refresh`)
      .then((response) => response.data);
  },

  getActivity(itemKey) {
    return req
      .get(`/items/${itemKey}/activity`)
      .then((response) => response.data);
  },

  addProtect(itemKey, form) {
    console.log(`items.addProtect(${itemKey}, ${form})`);
    return req
      .put(`/items/${itemKey}/connectRecord`, form)
      .then((response) => response.data);
  },
};
