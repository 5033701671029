import req from './axios';

export default {
  getApproval(shipmentKey) {
    console.log(`shipments.getApproval(${shipmentKey})`);
    return req
      .get(`/shipments/${shipmentKey}/approval`)
      .then((response) => response.data);
  },

  purchaseLabel(shipmentKey) {
    console.log(`shipments.getApproval(${shipmentKey})`);
    return req
      .post(`/shipments/${shipmentKey}/label`)
      .then((response) => response.data);
  },

  /*
   * form:
   *  state: the new state,
   *  message: the reason for changing state,
   */
  updateState(shipmentKey, form) {
    console.log(`shipments.updateState(${shipmentKey}, ${form})`);
    return req
      .patch(`/shipments/${shipmentKey}/state`, form)
      .then((response) => response.data);
  },

  pack(shipmentKey) {
    console.log(`shipments.updateState(${shipmentKey})`);
    return req
      .put(`/shipments/${shipmentKey}/pack`)
      .then((response) => response.data);
  },

  updateAddress(shipmentKey, form) {
    console.log(`shipments.updateAddress(${shipmentKey}, ${form})`);
    return req
      .patch(`/shipments/${shipmentKey}/address`, form)
      .then((response) => response.data);
  },

  refresh(shipmentKey) {
    console.log(`shipments.refresh(${shipmentKey})`);
    return req
      .put(`/shipments/${shipmentKey}/refresh`)
      .then((response) => response.data);
  },

  createScanForm(form) {
    console.log(`shipments.createScanForm(`, form, `)`);
    return req
      .post(`/shipments/scanform`, form)
      .then((response) => response.data);
  },

  addCustoms(shipmentKey, form) {
    console.log(`shipments.addCustoms(${shipmentKey}, `, form, `)`);
    return req
      .post(`/shipments/${shipmentKey}/customs`, form)
      .then((response) => response.data);
  },

  closeSimple(shipmentKey) {
    console.log(`shipments.closeSimple(${shipmentKey})`);
    return req
      .post(`/shipments/${shipmentKey}/close`, { state: 'done' })
      .then((response) => response.data);
  },
};
