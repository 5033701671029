import req from './axios';

export default {
  startAudit(form) {
    return req.post('/audit/', form).then((response) => response.data);
  },

  get(auditKey) {
    return req.get(`/audit/${auditKey}`).then((response) => response.data);
  },

  list(orgKey) {
    return req
      .get('/audit/', { params: { org: orgKey } })
      .then((response) => response.data);
  },

  check(auditKey, form) {
    return req
      .put(`/audit/${auditKey}/check`, form)
      .then((response) => response.data);
  },

  finalize(auditKey, form) {
    return req
      .post(`/audit/${auditKey}/finalize`, form)
      .then((response) => response.data);
  },
};
