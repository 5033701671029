import req from './axios'

export default {
  unshorten(url) {
    return req
      .get(`/utils/unshorten`, { params: { url: url } })
      .then((response) => response.data)
  },

  uploadImage(form) {
    console.log(`API: utils.uploadImage(${form})`)
    return req.post(`/utils/image`, form).then((response) => response.data)
  },

  appVersion() {
    return req.get(`/utils/version`).then((response) => response.data)
  },

  event(event, org, body) {
    console.log(`API: utils.event(${event}, ${org}, ${body})`)
    let form = {
      event: event,
      org: org,
      body: body || null,
    }
    return req
      .post(`/utils/analytics`, form)
      .then((response) => response.data)
      .catch((err) => console.log(err))
  },

  theftReport(form) {
    console.log(`API: utils.theftReport(${Object.entries(form)})`)
    return req
      .post(`/utils/theft`, form)
      .then((response) => response.data)
  },

  theftContact(reportKey, form) {
    console.log(`API: utils.theftContact(${reportKey}, ${Object.entries(form)})`)
    return req
      .post(`/utils/theft/${reportKey}/contact`, form)
      .then((response) => response.data)
  },
}
